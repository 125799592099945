import { NavLink } from "react-router-dom";
import "../Components/WcardStyles.css";

const Wcard = () => {
  return (
    <div className="card-container1">
      <div className="card-body1">
        <div className="img">
          <img
            src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80"
            alt=""
          />
        </div>
        <h1>Projects 1</h1>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque error
          explicabo magni sequi voluptas id iusto nihil impedit porro!
          Cupiditate?
        </p>
        <div className="btn-outer-project">
        {/* <button className="btn-project">Read More</button> */}
        <NavLink to='url.com' className="btn-project">Know More</NavLink>
        <NavLink to='url.com' className="btn-project">Read More</NavLink>
        </div>
      </div>

      <div className="card-body1">
        <div className="img">
            <img src="https://images.unsplash.com/photo-1589652717521-10c0d092dea9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" alt=""/>
        </div>
        <h1>Projects 2</h1>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque error
          explicabo magni sequi voluptas id iusto nihil impedit porro!
          Cupiditate?
        </p>
        <div className="btn-outer-project">
        <NavLink to='url.com' className="btn-project">Know More</NavLink>
        <NavLink to='url.com' className="btn-project">Read More</NavLink>
        </div>
      </div>

      <div className="card-body1">
        <div className="img img1">
            <img src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" alt=""/>
        </div>
        <h1>Projects 3</h1>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque error
          explicabo magni sequi voluptas id iusto nihil impedit porro!
          Cupiditate?
        </p>
        <div className="btn-outer-project">
        <NavLink to='url.com' className="btn-project">Know More</NavLink>
        <NavLink to='url.com' className="btn-project">Read More</NavLink>
        </div>
      </div>
    </div>
  );
};

export default Wcard;
