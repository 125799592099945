import React,{useState} from "react";
import { Link } from "react-router-dom";
import "../Components/NavbarStyle.css";




import { FaBars,FaTimes } from "react-icons/fa";

const Navbar = () => {

const [click,setClick] = useState(false);
const[color,setColor] = useState(false);

const ChangeColor =()=>{
  if(window.scrollY>=1){
    setColor(true);
  }else{
    setColor(false);
  }
}

window.addEventListener('scroll',ChangeColor);

  return (
    <>
      <div className={color?'header header-bg':'header'}>
        <Link to="/">
          <h1>Port<span>folio</span></h1>
        </Link>

        <ul className={click?'menu active':'menu'}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/project">Project</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>


        <div className="hamBurger" onClick={()=>setClick(!click)}>
            {click?  <FaBars size={20} style={{ color: "white" }} />:<FaTimes size={20} style={{ color: "red" }} />}
         

        </div>
      </div>
    </>
  );
};

export default Navbar;
