import React from "react";
import Navbar from "../Components/Navbar";

import Footer from "../Components/Footer";
import Form from '../Components/Form'
import HeroImg2 from "../Components/HeroImg2";

const Contact=()=>{
    return(<div>
    <Navbar/>
    <HeroImg2 heading="Contact" text="This is heading from contact"/>
    <Form />
    <Footer />
    </div>)
}


export default Contact;