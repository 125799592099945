import React from "react";
import "./index.css"
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import Project from './routes/Project';

function App() {
  return (
    <div >
      <BrowserRouter>
    
        <Routes>
       

            <Route path='/' element={<Home />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/contact' element={<Contact />}/>
            <Route path='/project' element={<Project />}/>
        </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
