import '../Components/HeroImgTwoStyles.css'


const HeroImg2=(props)=>{
    return(<div className='container-project'>
        <div className='headings'>
            <h1>{props.heading}</h1>
            <p>{props.text}</p>
        </div>
    </div>)
}


export default HeroImg2;