import React from "react";
import Navbar from "../Components/Navbar";
import HeroImg from "../Components/HeroImg";
import Footer from "../Components/Footer";

const Home=()=>{
    return(<div>
        
    <Navbar />
    <HeroImg/>
    <Footer/>
    </div>)
}


export default Home;