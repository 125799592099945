import "../Components/CardStyles.css";

const Card = () => {
  return (
    <div className="card-container">
    
      <div className="card">
        <div className="course">
        
          <h3>- Course-1 -</h3>
        </div>

        <p className="price">Rs-999</p>
        <div className="card-content">
          <p>-2Days-</p>
          <p>-5Pages-</p>
          <p>-Features-</p>
          <p>-Responsive Design</p>
          <button className="btn-card">Enroll Now!</button>
        </div>
      </div>
   


    
      <div className="card">
        <div className="course">
        
          <h3>- Course-2 -</h3>
        </div>

        <p className="price">Rs-2999</p>
        <div className="card-content">
          <p>-15Days-</p>
          <p>-20Pages-</p>
          <p>-Features-</p>
          <p>-Front end developer</p>
          <button className="btn-card">Enroll Now!</button>
        </div>
      </div>
     



      {/* <div className="margin"> */}
      <div className="card">
        <div className="course">
        
          <h3>- Course-3 -</h3>
        </div>

        <p className="price">Rs-4999</p>
        <div className="card-content">
          <p>-60Days-</p>
          <p>-100Pages-</p>
          <p>-Features-</p>
          <p>-Full Stack web Developer</p>
          <button className="btn-card">Enroll Now!</button>
        </div>
      </div>
      </div>
    // </div>
  );
};

export default Card;
