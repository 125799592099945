import React from "react";

import Navbar from "../Components/Navbar";

import Footer from "../Components/Footer";
import HeroImg2 from "../Components/HeroImg2";
import AboutContent from '../Components/AboutContent'

const About=()=>{
    return(<div>
        {/* <Navbar /> */}
       <Navbar/>
       <HeroImg2 heading="ABOUT" text="This is heading from about"/>
       <AboutContent/>
       <Footer/>
    </div>)
}


export default About;