import React from "react";
import Navbar from "../Components/Navbar";

import Footer from "../Components/Footer";
import HeroImg2 from "../Components/HeroImg2";
import Card from '../Components/Card'
import Wcard from "../Components/Wcard";



const Project=()=>{
    return(<div>
       <Navbar/>
       <HeroImg2 heading="PROJECTS." text="This is heading from projects"/>
       <Card/>
       <Wcard/>
       <Footer/>
    </div>)
}


export default Project;