import "../Components/HeroImgStyles.css";
import BgImg from "../assests/bg-img.jpg";
import { Link } from "react-router-dom";


const HeroImg = () => {
  return (
    <div className="hero">
      <div className="img-outer">
        <img className="img-inner" src={BgImg} alt="" />
      </div>

      <div className="content">
        <p>Hi,My name is Alok Shakya</p>
        <h1>I'm React Developer</h1>
        <div className="button-outside">
            <Link className="btn" to="/project">Projects</Link>
            <Link className="btn btn-light" to="/contact">Contact</Link>
        </div>
      </div>
      
    </div>
    
  );
};

export default HeroImg;
