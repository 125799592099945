import '../Components/AboutContentStyles.css'
import img1 from '../assests/im1.jpg'
import img2 from '../assests/im2.jpg'
import { Link } from 'react-router-dom'
const AboutContent=()=>{
    return(
        <div className="About-container">
            <div className="left">
                <h2>Alok Shakya</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil, porro!</p>
              <Link to="/contact"> <button className="btn">Contact Us</button></Link> 
            </div>


            <div className="right-container-stack">
                <div className="img-stack top ">
                    <img src={img1}/>
                </div>
                <div className="img-stack bottom">
                    <img src={img2}/>
                </div>
            </div>
            
        </div>
    )
}



export default AboutContent;