import '../Components/FormStyles.css'



const Form=()=>{
    return(<div>

<div className='form-outer'>
    <form className='form-input-label'>
        <label>Name:</label>
        <input type='text' />
        <label>Email:</label>
        <input type='text' />
        <label>Subject:</label>
        <input type='text' />
        <label>Query:</label>
        <textarea rows='6' placeholder='Enter your message here' />
        <button class='form-btn'>Submit</button>
    </form>
    </div>
    </div>)
}





export default Form;